import React from "react"

import Layout from "components/Layout/Layout"

import PageIndex from "components/PageIndex/PageIndex"

import useChangeLanguage from "hooks/useChangeLanguage"

const Index = ({ pageContext }) => {
  const { lang, cases } = pageContext
  useChangeLanguage(lang)
  return (
    <Layout color="white">
      <PageIndex cases={cases} />
    </Layout>
  )
}

export default Index
