import React from "react"
import { Link } from "gatsby"

import { useTranslation } from "react-i18next"

import formatDate from "utils/formatDate"
import Arrow from "components/common/Arrow/Arrow"

import pt from "prop-types"

import css from "./NewsItem.module.scss"

const NewsItem = ({ title, slug, pubDate }) => {
  const {
    i18n: { language },
  } = useTranslation()

  return (
    <div className={css.container}>
      <Link to={`/${language}/news/${slug}/`} className={"p"}>
        {title}
      </Link>
      <div className={css.bottom}>
        <p className={"small"}>{formatDate(pubDate)}</p>
        <Link to={`/${language}/news/${slug}/`} className={`p ${css.arrow}`}>
          <Arrow color={"silver"} size={"big"} />
        </Link>
      </div>
    </div>
  )
}

NewsItem.propTypes = {
  id: pt.number,
  title: pt.string,
  slug: pt.string,
  pubDate: pt.string,
}

export default NewsItem
