import React from "react"

import { Link } from "@reach/router"
import Arrow from "components/common/Arrow/Arrow"

import css from "./Statistics.module.scss"

const Statistics = ({ contents }) => {
  return (
    <section className={css.container}>
      <div className={css.inner}>
        <div className={css.left}>
          <div>
            <h2>{contents.infoTextTitleMain}</h2>
            <p>{contents.infoTextTitle}</p>
            <p>{contents.infoTextDescription}</p>

            <a href={contents.infoStrategyLink} className={css.arrow}>
              {contents.infoStrategyText} <Arrow color={"white"} />
            </a>
          </div>
          <a
            className={css.link}
            href={contents.infoTextOonLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={contents.infoTextOonImage}
              alt={contents.infoTextOonLink}
            />
          </a>
        </div>
        <div className={css.right}>
          <div className={css.top} />
          <div className={css.bottom}>
            <h4>{contents.infoText}</h4>
            <div className={css.actions}>
              <svg
                width="84"
                height="84"
                viewBox="0 0 84 84"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5996 10.5V73.5H51.4496C55.2296 73.5 58.0576 72.758 60.7876 70.35C63.6296 67.942 65.9256 65.618 68.3476 62.79C70.7556 60.06 71.3856 57.33 71.3856 53.55V10.5H12.5996ZM46.1996 64.05H19.9496V57.75H46.1996V64.05ZM64.0496 51.45H19.9496V45.15H64.0496V51.45ZM64.0496 38.85H19.9496V32.55H64.0496V38.85ZM64.0496 26.25H19.9496V19.95H64.0496V26.25Z"
                  fill="#5FAF2D"
                />
              </svg>
              <a href={contents.infoTextLink}>
                <Arrow color={"white"} className={css.arrow} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Statistics
