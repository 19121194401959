import React from "react"
import { Link } from "gatsby"

import useUrlPrefix from "hooks/useUrlPrefix"

import Arrow from "components/common/Arrow/Arrow"

import CaseTop from "../CaseTop"

import getRandomDummy from "../getRandomImage"

import css from "./CaseItemSmall.module.scss"

const CaseItemSmall = ({ goal, image, brands, slug, title }) => {
  const urlPrefix = useUrlPrefix()
  const resImage = image?.length > 0 ? image : getRandomDummy()
  return (
    <Link to={urlPrefix(`/cases/${slug}`)} className={css.wrapper}>
      <div
        className={css.image}
        style={{ backgroundImage: `url(${resImage})` }}
      >
        <CaseTop goal={goal} brands={brands} className={css.top} />
      </div>
      <div className={css.body}>
        <p>{title}</p>
        <Arrow color={"silver"} className={css.arrow} />
      </div>
    </Link>
  )
}

export default CaseItemSmall
