import { useState } from 'react'

import i18n from 'locales/i18n'

const useChangeLanguage = (lang) => {
  const [cLang, setCLang] = useState(null)
  if (cLang !== lang) {
    i18n.changeLanguage(lang)
    setCLang(lang)
  }
  return lang
}

export default useChangeLanguage
