import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import { Link } from 'gatsby'

import NewsItem from 'components/common/NewsItem/NewsItem'
import useContents from 'hooks/useContents'

import pt from 'prop-types'

import classes from 'utils/classes'
import css from './NewsList.module.scss'

const NewsList = ({ count = 0, list = [], className, grey = false, filter = null }) => {
  const { t, i18n: { language }} = useTranslation()
  const contents = useContents()
  const toRender = list.slice(0, 4)
  const isMobile = useMediaQuery({ query: '(max-width: 650px)' })

  if (toRender.length === 0) return null
  return <div className={classes(css.container, grey && css.grey, className)}>
    <div className={css.header}>
      <h3>{contents.newsTitle}</h3>
      <Link
        to={`/${language}/news/page/1/`}
        className={'small'}
        state={{ filter }}
      >
        {isMobile ? t('All news mobile') : t('All news')} {count && `(${count})`}
      </Link>
    </div>
    <div className={css.list}>
      {toRender.map((data, index) => <NewsItem key={index} {...data} />)}
    </div>
  </div>
}

NewsList.propTypes = {
  count: pt.number,
  list: pt.arrayOf(
    pt.shape({
      id: pt.number,
      title: pt.string,
      slug: pt.string,
      date: pt.string,
    })
  ).isRequired
}

export default NewsList
