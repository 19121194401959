import React from 'react'
import { setBlack } from 'state/headerState'

const withBlackHeader = WrappedComponent => {
  return class withBlackHeader extends React.Component {
    constructor(props) {
      super(props)
      setBlack(true)
    }

    componentWillUnmount() {
      setBlack(false)
    }

    render() {
      const { props } = this
      return <WrappedComponent {...props} />
    }
  }
}

export default withBlackHeader
