import React, { useState, useRef } from "react"
import { Link } from "gatsby"

import { gsap, Power2 } from "gsap"
import { CSSPlugin } from "gsap/CSSPlugin"

import { useTranslation } from "react-i18next"

import { setOpened as setMenuOpened } from "state/menuState"

import pt from "propTypes"

import classes from "utils/classes"

import Arrow from "./assets/arrow.inline.svg"
import css from "./Hero.module.scss"

gsap.registerPlugin(CSSPlugin)

const prepareString = string =>
  string.replace("(", "<span>").replace(")", "</span>")

const Hero = ({ goals = [], bg }) => {
  const [hovered, setHovered] = useState(false)
  const [img, setImg] = useState(null)
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const panelRef = useRef()

  const setHoveredElement = element => {
    if (!element) return
    const block = element.closest(`.${css.item}`)

    const { offsetTop, offsetHeight } = block
    gsap.to(panelRef.current, {
      y: offsetTop + offsetHeight / 2,
      ease: Power2.easeInOut,
      duration: 0.5,
    })
  }

  return (
    <section
      className={classes(css.container, hovered && css.hovered)}
      style={{ backgroundImage: `url(${bg})` }}
    >
      {goals.map(({ banner }, index) => (
        <div
          className={classes(css.img, img === index && css.active)}
          key={index}
          style={{ backgroundImage: `url(${banner})` }}
        />
      ))}
      <div className={css.circle}>
        <div className={css.subline}>{t("x5 goals")}</div>
        <svg
          width="555"
          height="660"
          viewBox="0 0 555 660"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <path
              opacity="0.7"
              d="M471.609 26.3501C525.801 61.0026 553.23 126.333 553.676 204.054C554.122 281.77 527.583 371.804 473.926 455.717C420.269 539.631 349.679 601.497 278.948 633.7C208.211 665.905 137.401 668.418 83.2085 633.766C29.0157 599.113 1.58725 533.783 1.1409 456.062C0.694584 378.346 27.2336 288.312 80.8906 204.398C134.548 120.485 205.138 58.6184 275.869 26.4161C346.606 -5.78873 417.416 -8.30243 471.609 26.3501Z"
              stroke="white"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="555" height="660" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className={css.list}>
        <div className={css.panel} ref={panelRef} />
        {goals.map(
          ({ name, leftText, rightText, leftIcon, rightIcon, slug }, index) => (
            <div
              key={index}
              className={css.item}
              onMouseEnter={e => {
                setHovered(true)
                setMenuOpened(false)
                setImg(index)
                setHoveredElement(e.target)
              }}
              onMouseLeave={() => {
                setHovered(false)
                setImg(null)
              }}
            >
              <div className={css.inner}>
                {leftText && (
                  <div
                    className={css.text}
                    dangerouslySetInnerHTML={{
                      __html: prepareString(leftText),
                    }}
                  />
                )}
                {leftIcon && (
                  <img className={css.icon} src={leftIcon} alt={name} />
                )}
                <Link to={`/${language}/goals/${slug}/`}>
                  <h2>{name}</h2>
                </Link>
                {rightIcon && (
                  <img className={css.icon} src={rightIcon} alt={name} />
                )}
                {rightText && (
                  <div
                    className={css.text}
                    dangerouslySetInnerHTML={{
                      __html: prepareString(rightText),
                    }}
                  />
                )}
              </div>
            </div>
          )
        )}
      </div>
      {/*{language === "ru" && (*/}
      {/*  <div className={css.mapLinks}>*/}
      {/*    <Link to={`/${language}/map/initiatives/`} className={css.link}>*/}
      {/*      Карта инициатив в магазинах <Arrow />*/}
      {/*    </Link>*/}
      {/*    <Link to={`/${language}/map/recycle/`} className={css.link}>*/}
      {/*      Карта технологий по переработке <Arrow />*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*)}*/}
    </section>
  )
}

Hero.propTypes = {
  goals: pt.goalArray,
}

export default Hero
