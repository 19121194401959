import React from 'react'

import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'

import classes from 'utils/classes'

import css from './Popup.module.scss'

const Popup = ({ children, topChild, opened = false, buttonText = 'Закрыть', buttonCallback }) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery({ query: '(max-width: 650px)' })

  return <div className={classes(css.container, opened && css.opened)}>
    <div className={css.inner}>
      {topChild && <div className={css.top}>
        {topChild}
      </div>}
      {children}
      {!isMobile && <button className={css.close} onClick={() => buttonCallback && buttonCallback()}>
        <span>{buttonText || t('Close')}</span>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="20" cy="20" r="19.5" stroke="#2A2D2F"/>
          <rect x="30.002" y="9.93935" width="0.535356" height="28.3739" rx="0.267678" transform="rotate(45 30.002 9.93935)" stroke="black" strokeWidth="0.535356"/>
          <rect x="30.3793" y="30.002" width="0.535356" height="28.3739" rx="0.267678" transform="rotate(135 30.3793 30.002)" stroke="black" strokeWidth="0.535356"/>
        </svg>
      </button>}
    </div>

    {isMobile && <button className={css.button} onClick={() => buttonCallback && buttonCallback()}>
      {buttonText || t('Close')}
    </button>}
  </div>
}

export default Popup
