import React from 'react'

import cs from 'utils/classes'

import css from './CaseTop.module.scss'

const Brand = ({ img }) => <div className={css.brand}><div className={css.brandImage} style={{ backgroundImage: `url(${img})` }} /></div>

const CaseTop = ({ goal, brands, outline, className }) => <div className={cs(css.top, outline && css.outline, className)}>
  <div className={css.goal}>
    {goal.name}
  </div>
  {brands && brands.map(brand => <Brand key={brand.id} img={brand.icon} />)}
</div>

export default CaseTop
