import React from "react"

import css from './PageRules.module.scss'

import withBlackHeader from 'HOC/withBlackHeader'

const PageRules = ({ content }) => {
  return (
    <div className={css.content} dangerouslySetInnerHTML={{ __html: content }} />
  )
}

export default withBlackHeader(PageRules)
