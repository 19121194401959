import React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'

import useContents from 'hooks/useContents'

import CaseItem from 'components/common/Cases/CaseItemSmall'

import css from './CasesList.module.scss'

const options = {
  pageDots: false,
  cellAlign: 'left',
  contain: true,
  freeScroll: true,
  adaptiveHeight: true,
}

const Flickity =
  process.browser
    ? require("react-flickity-component")
    : () => null

const CasesList = ({ list, count, filter = null }) => {
  // const toRender = list.slice(0, 4)
  const toRender = list

  const { t, i18n: { language }} = useTranslation()

  const contents = useContents()

  if (toRender.length === 0) return null

  return <div className={css.container}>
    <div className={css.header}>
      <h3>{contents.casesTitle || t('cases_title')}</h3>

      <Link
        to={`/${language}/cases/page/1/`}
        className={'small'}
        state={{ filter }}
      >
        {t('All cases')} {count && `(${count})`}
      </Link>
    </div>
    <Flickity className={css.carousel} options={options} disableImagesLoaded>
      {toRender.map((item) => <CaseItem {...item} key={item.id} />)}
    </Flickity>
  </div>
}

export default CasesList
