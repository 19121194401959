import React, { useEffect } from "react"

import useContents from "hooks/useContents"
import useGoals from "hooks/useGoals"
import useArticles from "hooks/useArticles"

import Hero from "components/PageIndex/Hero/Hero"
import Statistics from "components/PageIndex/Statistics/Statistics"
// import Maps from "components/PageIndex/Maps"
import NewsList from "components/PageIndex/NewsList/NewsList"
import CasesList from "components/common/Cases/CasesList"
import Subscription from "../PageArticles/Subscription/Subscription"
import SEO from "components/seo"

const PageIndex = ({ cases }) => {
  const goals = useGoals()
  const newsList = useArticles()
  const contents = useContents()

  return (
    <>
      <SEO />
      <Hero goals={goals.results} bg={contents.image} />
      <Statistics contents={contents} />
      <NewsList list={newsList.results} count={newsList.count} />
      {/*<Maps />*/}
      <CasesList list={cases.results} count={cases.count} />
      {/*{interviewsList.results.length > 0 && <QuotesList list={interviewsList.results} maxToShow={3}/>}*/}
      <Subscription grey />
    </>
  )
}

export default PageIndex
