import { useStaticQuery, graphql } from 'gatsby'

import { useTranslation } from 'react-i18next'

export const useArticles = () => {
  const { i18n: { language } } = useTranslation()
  const { articlesRu, articlesEn } = useStaticQuery(
    graphql`
      query ArticlesQuery {
        articlesRu {
          count
          results {
            content
            id
            slug
            title
            pubDate
          }
        }
        articlesEn {
          count
          results {
            content
            id
            slug
            title
            pubDate
          }
        }
      }

    `
  )
  if (language === 'en') return articlesEn
  return articlesRu
}

export default useArticles
