import React, { useState, useRef, useEffect } from "react"

import { subscribe } from "api"
import { useTranslation } from "react-i18next"

import useContents from "hooks/useContents"

import classes from "utils/classes"
import isEmailValid from "utils/isEmailValid"

import Popup from "components/common/Popup/Popup"
import PageRules from "components/common/Rules/PageRules"

import css from "./SubscriptionBlock.module.scss"

const SubscriptionBlock = ({ cb, className }) => {
  const [lang, setLang] = useState("ru")
  const [rules, setRules] = useState(false)
  const [policy, setPolicy] = useState(false)
  const [license, setLicense] = useState(false)
  const [email, setEmail] = useState("")
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const description = useRef()

  const contents = useContents()

  const { t } = useTranslation()

  const submit = e => {
    e.preventDefault()

    if (!isEmailValid(email)) {
      setError(true)
      return
    }

    subscribe({ email, lang }).finally(() => {
      setSuccess(true)
      setEmail("")
    })
  }

  const onChange = e => {
    setError(false)
    setEmail(e.target.value)
  }

  const clickActions = {
    rules: setRules,
    policy: setPolicy,
    license: setLicense,
  }

  const handleLinkClick = e => {
    const values = ["rules", "policy", "license"]
    const { href } = e.target
    const hash = href.substring(href.indexOf("#") + 1)

    if (hash.length === 0) return

    e.preventDefault()

    if (!values.includes(hash)) return

    const action = clickActions[hash]

    action(true)
  }

  useEffect(() => {
    if (!contents.subscribeRightsText) return

    const { current } = description
    const links = Array.from(current.querySelectorAll("a"))

    links.forEach(link => link.addEventListener("click", handleLinkClick))

    return () => {
      links.forEach(link => link.removeEventListener("click", handleLinkClick))
    }
  }, [contents])

  return (
    <>
      <Popup opened={rules} buttonCallback={() => setRules(false)}>
        <PageRules content={contents.rulesText} />
      </Popup>
      <Popup opened={policy} buttonCallback={() => setPolicy(false)}>
        <PageRules content={contents.policyText} />
      </Popup>
      <Popup opened={license} buttonCallback={() => setLicense(false)}>
        <PageRules content={contents.licenseText} />
      </Popup>

      <div className={classes(css.sub, className)}>
        <div className={css.subTitle}>
          <h2>{contents.subscribeText}</h2>
        </div>

        <div className={css.subForm}>
          <div className={css.wrapLang}>
            <label className={css.label}>
              {t("Select your mailing language")}
            </label>

            <div className={css.switchLang}>
              <div className={css.switch} onClick={() => setLang("ru")}>
                <div className={css.circleBlock}>
                  <div
                    className={classes(css.circle, lang === "ru" && css.white)}
                  />
                </div>
                <p className={css.rusTitle}>{t("Russian")}</p>
              </div>
              <div className={css.switch} onClick={() => setLang("en")}>
                <div className={css.circleBlock}>
                  <div
                    className={classes(css.circle, lang === "en" && css.white)}
                  />
                </div>
                <p className={css.engTitle}>{t("English")}</p>
              </div>
            </div>

            {!success && (
              <form className={css.form} onSubmit={submit}>
                <div className={css.inputWrap}>
                  <input
                    type="text"
                    placeholder={t("Email")}
                    value={email}
                    onChange={onChange}
                  />
                  {error && (
                    <div className={css.inputError}>
                      {t("Invalid email format")}
                    </div>
                  )}
                </div>
                <button type="submit" className={css.submitButton}>
                  <span className={css.buttonSpan}>{t("Subscribe")}</span>
                </button>
              </form>
            )}

            {success && (
              <h3 className={css.success}>
                {t("You have successfully subscribed")}
              </h3>
            )}

            <div
              ref={description}
              className={css.description}
              dangerouslySetInnerHTML={{ __html: contents.subscribeRightsText }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default SubscriptionBlock
