import React from 'react'

import cs from 'utils/classes'
import css from './Subscription.module.scss'

import useContents from 'hooks/useContents'

import SubscriptionBlock from 'common/SubscriptionBlock/SubscriptionBlock'

const Subscription = ({ grey = false, padding = false}) => {
  const contents = useContents()

  return (
    <div className={cs(css.subBlocks, grey && css.grey, padding && css.padding)}>

      <div className={css.imageBlock}>
        {contents.subscribeImage && <img src={contents.subscribeImage} alt='Подписка на публикации Х5'/>}
      </div>

      <SubscriptionBlock className={css.newStyle}/>

    </div>
)}

export default Subscription
